<template>
  <DialogBase class="w-full max-w-2xl" :open="feedbackFormDialog" @close="closeDialog" :can-close="!submitting">
    <h2 class="font-bold text-2xl mb-4">{{ $t('Send Feedback') }}</h2>
    <ControlsLoader v-if="submitting" class="mt-4" :message="$t('sending.feedback')"/>
    <div v-else-if="submissionSuccess" class="mt-6 text-center">
      <p class="text-sm font-bold text-dark-900 flex flex-col justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-12 h-12 mb-2 text-green-200">
          <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
        </svg>
        {{ $t('feedback.sent') }}</p>
    </div>
    <div v-else class="mt-6">
      <div class="flex flex-col">
        <label for="feedback-email"  class="text-sm font-bold mb-2"
          :class="{'text-red-error': emailError}"
        >{{ $t('Email') }}</label>
        <input id="feedback-email" v-model="email" class="bg-input-background border border-input-border f-input p-2" @focus="clearErrors"/>
        <p v-if="emailError" class="text-sm text-red-error mt-1 flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.8 5.31864V2.4H5.2V5.31864L5.27164 7.37288H6.72836L6.8 5.31864ZM6.56805 9.6494C6.72268 9.49477 6.8 9.307 6.8 9.08609C6.8 8.86518 6.72268 8.67583 6.56805 8.51804C6.41341 8.36025 6.22564 8.28136 6.00473 8.28136C5.78383 8.28136 5.59448 8.36025 5.43669 8.51804C5.2789 8.67583 5.2 8.86518 5.2 9.08609C5.2 9.307 5.2789 9.49477 5.43669 9.6494C5.59448 9.80404 5.78383 9.88136 6.00473 9.88136C6.22564 9.88136 6.41341 9.80404 6.56805 9.6494Z" fill="#F0001B"/>
          </svg>
          {{ $t('feedback.invalid.email.address') }}
        </p>
      </div>
      <div class="flex flex-col mt-4">
        <label for="feedback-type" class="text-sm font-bold mb-2">{{ $t('Feedback Type') }}</label>
        <select id="feedback-type" v-model="type" class="border bg-input-background border-input-border f-input p-2" @focus="clearErrors">
          <option value="feature">{{ $t('Feature Request') }}</option>
          <option value="bug">{{ $t('Bug') }}</option>
          <option value="question">{{ $t('Question') }}</option>
          <option value="support_request">{{ $t('Support Request') }}</option>
          <option value="other">{{ $t('Other') }}</option>
        </select>
      </div>
      <div class="flex flex-col mt-4">
        <label for="feedback-text" class="text-sm font-bold mb-2"
           :class="{'text-red-error': messageError}"
        >
          {{ $t('message.field') }}
        </label>
        <textarea id="feedback-text" v-model="text" class="border bg-input-background border-input-border f-input p-2 h-24"  @focus="clearErrors"></textarea>
        <p v-if="messageError" class="text-sm text-red-error mt-1 flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.8 5.31864V2.4H5.2V5.31864L5.27164 7.37288H6.72836L6.8 5.31864ZM6.56805 9.6494C6.72268 9.49477 6.8 9.307 6.8 9.08609C6.8 8.86518 6.72268 8.67583 6.56805 8.51804C6.41341 8.36025 6.22564 8.28136 6.00473 8.28136C5.78383 8.28136 5.59448 8.36025 5.43669 8.51804C5.2789 8.67583 5.2 8.86518 5.2 9.08609C5.2 9.307 5.2789 9.49477 5.43669 9.6494C5.59448 9.80404 5.78383 9.88136 6.00473 9.88136C6.22564 9.88136 6.41341 9.80404 6.56805 9.6494Z" fill="#F0001B"/>
          </svg>
          {{ $t('feedback.invalid.message') }}
        </p>
      </div>

      <ControlsSmallerError class="mt-4" v-if="errorMessage" :message="$t(errorMessage)"/>
      <div class="flex items-center justify-end pt-4">
        <button class="disabled:opacity-50 disabled:bg-slate-700 font-bold text-white text-sm bg-brand-500 rounded-m py-3 px-6" @click="sendFeedback">{{ $t('Send Feedback') }}</button>
      </div>
    </div>
  </DialogBase>
</template>

<script setup>
import {useFeedbackStore} from "~/store/feedback";
import {useUtilsStore} from "~/store/utils";
import {useCookieStore} from "~/store/cookie";
import {storeToRefs} from "pinia";

const feedbackStore = useFeedbackStore();
const cookieStore = useCookieStore();
const utilsStore = useUtilsStore();
const config = useRuntimeConfig();

const {fetchError} = storeToRefs(feedbackStore);
const {feedbackFormDialog} = storeToRefs(utilsStore);
const {user} = storeToRefs(cookieStore);
const errorMessage = ref(null);
const emailError = ref(false);
const messageError = ref(false);

const submitting = ref(false);
const submissionSuccess = ref(false);

const text = ref('');
const type = ref('bug');
const email = ref('');

if (user.value) {
  email.value = user.value.username;
}

const clearErrors = () => {
  emailError.value = false;
  messageError.value = false;
  errorMessage.value = null;
}


const sendFeedback = () => {

  if (submitting.value) {
    return;
  }

  if (! email.value || ! email.value.includes('@')) {
    emailError.value = true;
    return;
  }

  if (! text.value || text.value.trim().length < 4) {
    messageError.value = true;
    return;
  }
  submissionSuccess.value = false;
  submitting.value = true;
  feedbackStore.sendFeedback(text.value, type.value, email.value, config);
  submitting.value = false;
  if (! fetchError.value) {
    submissionSuccess.value = true;
    setTimeout(() => {
      feedbackFormDialog.value = false;
      submissionSuccess.value = false;
      text.value = '';
    }, 1000);
  } else {
    errorMessage.value = 'feedback.error';
  }
}

const closeDialog = () => {
  feedbackFormDialog.value = false;
}

</script>

<style scoped>
dialog {
  min-height: 400px;
  @media (max-width: 640px) {
    min-height: 440px;
  }
}
</style>